.busy-indicator {
    position: fixed;
    top: 40%;
    left: 50%;
}

.loader{
    vertical-align: middle;
    font-size: 60px;
    text-align:center;
    width:100%;
}