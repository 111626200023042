.send_msg_form_items textarea {
    line-height: 28px;
    resize:none;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 11px;
    padding-right: 100px;
    padding-top: 7px;
    scrollbar-width: thin;
    border-radius: 5px;
    background: white;
    /*display: inline-block;*/
}
.scroll::-webkit-scrollbar {
    width: 6px;
}

.send_msg_form_items > button > div {
    display: inline-block;
    vertical-align: top;
}

.send_msg_form_items > button {
    font-style: normal;
    border: none;

    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0;
    background: #3880DB;
    border-radius: 4px;

    height: 44px;
    width: 96px;

    color: #FFFFFF;
    text-align: center;
}
.send_msg_form_items > button:disabled {
    background: #98AEC0;
}

.send_text{
    margin-right: 11px;
}
.send_icon {
    margin-left: 2px;
    margin-right: 12px;
    height: 18px;
    width: 18px;
}

.send_msg_form_items
{
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 43px;
    border-radius: 5px;
}

.send_msg_form {
    display: inline-block;
    box-sizing: content-box;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: auto;
    left: 24px;
    bottom: 23px;
    right: 24px;
    position: absolute;
    border: 1px solid #AFAFAF;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 1px;
}