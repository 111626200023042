.messages_container {
    margin-top:60px;
    padding: 6px 18px 24px 24px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 2px 9px #DCDFE5;
    border-radius: 4px;
    
    width: 100%;
    max-width:857px;
    height: 100%;
    max-height: 627px;
    min-height: 627px;
}

.messages_container.im-container{
    margin-top: 0;
    height: 100%;
    border: 1px solid #2970CA;
}

div.scroll.initial-message-height {
    height: 100%;
    max-height: 400px;
    min-height: 400px;
}

div.scroll {
    margin-top: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
    max-height: 480px;
    padding: 0;
    scrollbar-width: thin;
    scrollbar-color: #E0E0E0 #FFFFFF;
}

.scroll::-webkit-scrollbar {
    width: 6px;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #E0E0E0;
    outline: none;
    border-radius: 4px;
}

.initial-message {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 49px;
    /* or 350% */
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
    letter-spacing: 0.0890909px;
}

.initial-message span {
     width: 100%;
     margin-top: 30px;
 }

.bold {
    font-weight: bold;
}

.message-sent .bold {
    line-height: 19px;
}

.message-sent span {
    margin-top: 6px;
}

.message-sent {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0890909px;
    alignment: center;
    margin-bottom: 16px;
}

.message-sent > div.message-sent-description {
    width: 270px;
    display: inline-block;
}

.nomessage {
    position: relative;
}
.nomessage h3
{
    position: absolute;
    left: 4.86%;
    right: 44.58%;
    top: 15.62%;
    bottom: 74.36%;

    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.0890909px;

    color: #525A69;
}