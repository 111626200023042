
body{
    max-width: 1440px;
    /*background: #F9FAFD;*/
    background: transparent;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
}
@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar {
        height: 100%;
        width: calc(10% + 300px);
        background: none;
        max-width: 350px;
        margin: 0;
    }
    .navbar .bold {
        font-weight: bold;
    }
    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.0890909px;
    }
    .navbar-header {
        float: none;
    }
    .navbar .navbar-collapse {
        /*border-top: 1px solid #444;*/
        padding-top: 6px;
    }
    .navbar .container-fluid {
        padding: 46px 0 0 0;
        margin: 0 25px 0 0;
    }
    .navbar .container-fluid .navbar-brand {
        margin: 0;
    }
    .navbar .navbar-collapse > .scroll {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 430px;
        scrollbar-width: thin;
        scrollbar-color: #E0E0E0 #FFFFFF;
    }
    
    .scroll::-webkit-scrollbar-thumb {
        background-color: #E0E0E0;
        outline: 1px solid slategrey;
        border-radius: 4px;
    }
    
    .navbar ul {
        /*float: none;*/
        list-style: none;
        padding: 0;
        margin: 0;

    }
    .navbar li {
        box-shadow: 0 2px 9px #DCDFE5;
        border-radius: 4px;
        background: #FFFFFF;
        /*float: none;*/
        margin: 14px;
        height: 70px;
    }
    .navbar li:hover {
        border: 2px solid #2D9CDB;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px #F8F9FA;
    }

    .navbar .current {
        border: 2px solid #2D9CDB;
        box-sizing: border-box;
        box-shadow: 0 4px 4px #F8F9FA;
    }
    
    /*.navbar li a > div {*/
    /*    vertical-align: central;*/
    /*    align-items: center;*/
    /*    align-content: center;*/
    /*    margin-left: 24px;*/
    /*    margin-right: 24px;*/
    /*    display: table;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/
    
    .navbar li a {
        color:  #000000;
        height: 100%;
        text-decoration: none;
    }
    .navbar li a:hover  {
        color:  #000000;
    }
    .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        height: 100%;
        /*white-space: nowrap;*/
        overflow: hidden;
        /*text-overflow: ellipsis;*/
    }

    .navbar a > table {
        width: calc(100% - 48px);
        height: 100%;
        margin-left: 24px;
        margin-right: 24px;
    }
    
    .navbar .network-status {
        width: 100px;
    }

    .navbar .network-status span {
        color: #7FBC5B;
        border: 1px solid #7FBC5B;
        border-radius: 10px;
        padding: 0 10px;
    }
    .navbar .conversation-status {
        width: 15px;        
    }
}

/*@media (min-width: 992px) {*/
/*    */
/*    .navbar {*/
/*        width: calc(45% + 1px);*/
/*        !*max-width: 470px;*!*/
/*    }*/
/*    */
/*    .navbar .container-fluid {*/
/*        padding: 0;*/
/*        margin: 0 25px 0 40px;*/
/*    }*/
/*}*/
@media (min-width: 992px) {
    .navbar {
        /*width: calc(35% + 1px);*/
        max-width: 510px;
        width: 100%;
    }

    .navbar .container-fluid {
        margin: 0 35px 0 40px;
        padding-left: 20px;
    }
}

tr.nav-row-100 {
    height: 100%;
}

tr.nav-row-50 {
    height: 50%;
}