span.container-with-new-messages {
    color: #2F80ED;
}

span.last-message {
    margin-right: 10px;
}

.navbar .time-date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #828282;
}

.navbar li a .time-date:hover {
    color: #747474;
}