.col-offset-495 {
    padding-left: 20px;
    padding-right: 20px;
    z-index: 0;
}

.no-navbar {
    padding-left: 22px;
    padding-right: 22px;
}

@media (min-width: 768px) {
    .col-fixed-495 {
        max-width: 365px;
        position: fixed;
        height: 100%;
        z-index: 1;
    }

    .col-offset-495 {
        padding-left: 370px;
        z-index: 0;
    }

    .blocked-panel {
        position: fixed;
        z-index: 2;
        padding: 0 65px 0 74px;
        max-width: 1440px;
        width: 100%;
    }
}


@media (max-width: 767px) {
    body {
        padding-top: inherit;
    }
}

@media (min-width: 992px) {
    .col-fixed-495 {
        max-width: 505px;
        position: fixed;
        height: 100%;
        z-index: 1;
    }

    .col-offset-495 {
        padding-left: 520px;
        z-index: 0;
    }

    .blocked-panel {
        z-index: 2;
        position: fixed;
        padding: 0 65px 0 74px;
        max-width: 1440px;
        width: 100%;
    }
}
