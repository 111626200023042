.chat-header {
    display: inline-block;
    width: 100%;
}

.chat-header > div {
    margin-top: 4px;
    display: inline-block;
}

.chat-header div.title-container {
    width: 70%;
    height: 34px;
    overflow: auto;
}

.chat-header .buttons-container {
    float: right;
}

.chat-header button {
    border: none;
    background: none;
    padding: 0;
    color: #4F4F4F;
    margin: auto 5px;
    vertical-align: baseline;
}
.chat-header button:focus { outline: none; }

.chat-header .delete-button {
    font-size: 18px;
}

.chat-header .close-button {
    font-size: 18px;
}

.chat-header button.button-with-margin-20 {
    margin-right: 20px;
}
.chat-header span{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    
    line-height: 25px;
    letter-spacing: 0.114545px;
    padding-bottom: 0;
    color: #000000;
}

hr.chat-header {
    margin: 0;
}

.chat-header .invite-button {
    width: 84px;
    height: 34px;
    /*border: 1px solid #2970CA;
    border-radius: 4px; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #fff;
    color: #2970CA;
}

.chat-header .invite-button a{
    color: #2970CA;
}

.chat-header .in-network-button {
    width: 100px;
    height: 34px;
    border: 1px solid #2970CA;
    border-radius: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #fff;
    color: #2970CA;
}
