.unblocked-popup-header,
.unblocked-popup-footer {
    border: none;
}

.unblocked-popup-body {
    font-size: 20px;
}

.unblocked-popup-footer button {
    background: #2970CA;
    color: white;
}
