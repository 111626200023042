.incoming_msg_img {
    display: inline-block;
    width: 6%;
}
.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
    margin-top: 43px;
}
.received_width_msg > .msg_body {
    background: #E0E0E0 none repeat scroll 0 0;
    border-radius: 0 10px 10px 10px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    width: 100%;
    white-space: pre-line;
    padding: 13px 30px 13px 30px;
    word-break: break-word;
}

.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}
.sent_msg > .time_date_container{
    text-align: right;
    margin-right: 6px;
}
.received_width_msg > .time_date_container{
    margin-left: 6px;
}
.received_width_msg { width: 340px;}

.sent_msg > .msg_body {
    background: #2D9CDB none repeat scroll 0 0;
    color:#fff;

    border-radius: 10px 0 10px 10px;
    margin: 0;
    padding: 13px 30px 13px 30px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0890909px;
    

    width:100%;
    white-space: pre-line;
    word-break: break-word;
}

.outgoing_msg{ overflow:hidden;
}

.sent_msg {
    display: inline-block;
    float: right;
    width: 340px;
    margin-top: 43px;
}
/*.input_msg_write input {*/
/*    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;*/
/*    border: medium none;*/
/*    color: #4c4c4c;*/
/*    font-size: 15px;*/
/*    min-height: 48px;*/
/*    width: 100%;*/
/*}*/

.messages_container ul {
    padding: 0;
}
.messages_container li {
    padding: 0;
}


.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.picture_preview {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.picture_preview {
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    filter: blur(2px);
}

.media_icon:hover .picture_preview {
    filter: blur(0px);
}

.picture_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media {
    border-radius: 4px;
    cursor: pointer;
    margin: 4px;
    display: flex;
    width: 220px;
    height: 220px;
    justify-content: center;
    align-items: center;
}

.placeholder {
    background: rgba(22, 46, 52, 0.58);
}

.medias {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-flow: wrap;
}

.media_icon {
    margin: 4px;
    position: relative;
    border-radius: 4px;
    top: 0;
    overflow: hidden;
    height: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-basis: 220px;

}
