.blocked-label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    padding: 15px;
    vertical-align: middle;
    border-radius: 10px;
    background-color: #DE453B;
    color: white;
    width: 100%;
}

.blocked-label button {
    padding: 0;
}

.blocked-label > span, .blocked-label > svg {
    display: inline-block;
    margin-right: 10px;
}
