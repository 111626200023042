delete-popup-header,
.delete-popup-footer {
    border: none;
}

.delete-popup-header {
    font-size: 20px;
}

.delete-popup-body {
    font-size: 16px;
}

.delete-popup-footer button.delete {
    background: #2970CA;
    color: white;
}

.delete-popup-footer button.cancel {
    background: white;
    color: #2970CA;
    border: none;
    margin: 0 15px;
}