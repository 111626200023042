.chat-filter {
    display: flex;
    height: 44px;
    align-items: stretch;
    margin: 14px;

    background: #FFFFFF;
    box-shadow: 0 2px 9px #DCDFE5;
    border-radius: 4px;
}

.chat-filter > button {
    font-style: normal;
    border: none;
    outline: none;

    background: #3880DB;
    border-radius: 4px;

    width: 47px;

    color: #FFFFFF;
    text-align: center;
}

.chat-filter > div {
    width: 100%;
}

.chat-filter > div input {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding-top: 1px;

    padding-left: 28px;
}
